import React from 'react'
import SEO from '../components/seo'
import Mandelbrot, { MandelbrotProps } from '../components/Mandelbrot'

export default function MandelbrotPage({ location }) {
  const params = new URLSearchParams(location.search)
  const props: MandelbrotProps = {}
  // TODO value validation
  if (params.has('range')) {
    const [x1, y1, x2, y2] = params.get('range').split(',').map(Number)
    props.targetRange = { x1, x2, y1, y2 }
  }
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SEO pathName={ location.pathname }/>
      <Mandelbrot {...props} />
    </div>
  )
}
